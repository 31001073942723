var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm._m(0),
    _vm._m(1),
    _c("h1", [_vm._v("文档变更说明")]),
    _vm._m(2),
    _vm._m(3),
    _c("p", [
      _vm._v(
        "本文档用于于万科技智驾中控平台对外服务相关接口的技术说明，于万相关合作伙伴在获取开发者权限后，可参考此文档进行相关接口接入。"
      ),
    ]),
    _vm._m(4),
    _c("table", [
      _vm._m(5),
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v("api_url")]),
          _c(
            "td",
            [
              _vm._v("接口前置URL，格式为："),
              _c("font", { attrs: { color: "red" } }, [
                _vm._v("https://{租户二级域名}/api"),
              ]),
              _vm._v(
                "，比如租户二级域名为baidu.iot.revolution.auto，那么api_url则为https://baidu.iot.revolution.auto/api"
              ),
            ],
            1
          ),
        ]),
        _vm._m(6),
      ]),
    ]),
    _vm._m(7),
    _c("ul", [
      _c(
        "li",
        [
          _vm._m(8),
          _c("center", [
            _c("img", {
              attrs: {
                src: "https://yw.testiot.revolution.auto/sso.png",
                width: "50%",
              },
            }),
          ]),
          _c(
            "center",
            [
              _c("font", { attrs: { size: "2" } }, [
                _vm._v("（登录验证服务流程说明）"),
              ]),
            ],
            1
          ),
          _vm._m(9),
          _c("ol", [
            _c("li", [
              _c("h3", [_vm._v("获取token")]),
              _c(
                "p",
                [
                  _vm._v("用于获取访问本平台开放接口所需的token，"),
                  _c("font", { attrs: { color: "red" } }, [
                    _vm._v("token有效期为60分钟"),
                  ]),
                  _vm._v("，目前"),
                  _c("font", { attrs: { color: "red" } }, [
                    _vm._v("每天获取token的次数限制为200次"),
                  ]),
                  _vm._v(
                    "，获取到token后，请务必自行做临时保存，以管理token有效期，到期后需要重新获取新的token。"
                  ),
                ],
                1
              ),
              _vm._m(10),
              _c("h4", [_vm._v("请求参数")]),
              _vm._m(11),
              _vm._m(12),
              _vm._m(13),
              _vm._m(14),
              _vm._m(15),
              _vm._m(16),
              _vm._m(17),
              _vm._m(18),
              _vm._m(19),
              _vm._m(20),
              _vm._m(21),
              _vm._m(22),
              _c("h4", [_vm._v("返回结果")]),
              _vm._m(23),
              _c("h4", [_vm._v("返回数据结构")]),
              _vm._m(24),
              _vm._m(25),
            ]),
            _vm._m(26),
          ]),
        ],
        1
      ),
      _vm._m(27),
      _vm._m(28),
      _vm._m(29),
      _vm._m(30),
      _vm._m(31),
    ]),
    _vm._m(32),
    _vm._m(33),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "#changer-description" } }, [
          _vm._v("文档变更说明"),
        ]),
      ]),
      _c("li", [_c("a", { attrs: { href: "#summary" } }, [_vm._v("概述")])]),
      _c("li", [
        _c("a", { attrs: { href: "#name-description" } }, [_vm._v("名词解释")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "#api-reference" } }, [
          _vm._v("接口参考说明"),
        ]),
        _c("ul", [
          _c("li", [
            _c("a", { attrs: { href: "#1-sso" } }, [_vm._v("登录及验证服务")]),
            _c("ul", [
              _c("li", [
                _c("a", { attrs: { href: "#11-token" } }, [
                  _vm._v("1 获取token"),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#12-authRedirect" } }, [
                  _vm._v("2 验证token"),
                ]),
              ]),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#2-other-api" } }, [
              _vm._v("接口验证说明"),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#api-reference-vehicle" } }, [
              _vm._v("车辆信息接口"),
            ]),
            _c("ul", [
              _c("li", [
                _c("a", { attrs: { href: "#2021-11-22/vehicleList" } }, [
                  _vm._v("1 车辆列表"),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#2021-11-22/vehicleStatus" } }, [
                  _vm._v("2 车辆状态"),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#2021-11-22/dailyStatistics" } }, [
                  _vm._v("3 车辆行驶数据日统计"),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#2022-06-20/vehicleLocation" } }, [
                  _vm._v("4 获取车辆实时位置"),
                ]),
              ]),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#api-reference-vedio" } }, [
              _vm._v("视频信息接口"),
            ]),
            _c("ul", [
              _c("li", [
                _c("a", { attrs: { href: "#2021-11-22/videoHistoryQuery" } }, [
                  _vm._v(
                    "1 查询指定时间范围内的主摄像头历史视频记录(deprecated)"
                  ),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#2022-06-20/videoHistoryQuery" } }, [
                  _vm._v("2 查询指定时间范围内指定摄像头的历史视频记录"),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#2021-11-22/realTimeVideoUrl" } }, [
                  _vm._v("3 获取车辆上的主摄像头实时视频播放地址(deprecated)"),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#2022-06-20/realTimeVideoUrl" } }, [
                  _vm._v("4 获取车辆各摄像头的实时视频播放地址"),
                ]),
              ]),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#api-reference-task" } }, [
              _vm._v("任务信息接口"),
            ]),
            _c("ul", [
              _c("li", [
                _c("a", { attrs: { href: "#2022-12-21/taskRecord/list" } }, [
                  _vm._v("1 任务报告列表"),
                ]),
              ]),
              _c("li", [
                _c(
                  "a",
                  { attrs: { href: "#2022-12-21/taskRecord/trackCoordinate" } },
                  [_vm._v("2 任务报告行驶轨迹坐标")]
                ),
              ]),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#api-reference-warning" } }, [
              _vm._v("告警信息接口"),
            ]),
            _c("ul", [
              _c("li", [
                _c("a", { attrs: { href: "#2022-12-21/vehicleWarn/list" } }, [
                  _vm._v("1 告警数据列表"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "#api-limit-description" } }, [
          _vm._v("关于接口限制"),
        ]),
        _c("ul", [
          _c("li", [
            _c("a", { attrs: { href: "#ip-white-list" } }, [
              _vm._v("IP白名单"),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#api-limit" } }, [_vm._v("接口限流")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("a", { attrs: { name: "changer-description" } })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("thead", [
        _c("tr", [_c("th", [_vm._v("变更时间")]), _c("th", [_vm._v("说明")])]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v("2021-11-22")]),
          _c("td", [_vm._v("初始版本，版本号：2022-11-22")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2022-06-20")]),
          _c("td", [
            _vm._v("新增接口 "),
            _c("a", { attrs: { href: "#2022-06-20/realTimeVideoUrl" } }, [
              _vm._v("获取车辆各摄像头的实时视频播放地址"),
            ]),
            _c("br"),
            _vm._v("版本号：2022-06-20"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2022-06-22")]),
          _c("td", [
            _vm._v("新增接口 "),
            _c("a", { attrs: { href: "#2022-06-20/vehicleLocation" } }, [
              _vm._v("获取车辆实时位置"),
            ]),
            _c("br"),
            _vm._v("版本号：2022-06-20"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2022-07-04")]),
          _c("td", [
            _vm._v("接口 "),
            _c("a", { attrs: { href: "#2022-06-20/realTimeVideoUrl" } }, [
              _vm._v("获取车辆各摄像头的实时视频播放地址"),
            ]),
            _vm._v("增加返回数据：视频流ID(streamId)"),
            _c("br"),
            _vm._v("接口 "),
            _c("a", { attrs: { href: "#2022-06-20/vehicleLocation" } }, [
              _vm._v("获取车辆实时位置"),
            ]),
            _vm._v("增加返回数据：速度、电量百分比、车辆行驶方向"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2022-07-05")]),
          _c("td", [
            _vm._v("增加接口 "),
            _c("a", { attrs: { href: "#2022-06-20/videoHistoryQuery" } }, [
              _vm._v("查询指定时间范围内指定摄像头的历史视频记录"),
            ]),
            _c("br"),
            _vm._v("版本号：2022-06-20"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2022-09-12")]),
          _c("td", [
            _vm._v("接口 "),
            _c("a", { attrs: { href: "#2021-11-22/vehicleStatus" } }, [
              _vm._v("车辆状态"),
            ]),
            _vm._v("增加返回数据：车辆控制模式"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2023-01-04")]),
          _c("td", [
            _vm._v("新增接口 "),
            _c("a", { attrs: { href: "#2022-12-21/taskRecord/list" } }, [
              _vm._v("任务报告列表"),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("新增接口 "),
            _c(
              "a",
              { attrs: { href: "#2022-12-21/taskRecord/trackCoordinate" } },
              [_vm._v("任务报告行驶轨迹坐标")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v("新增接口 "),
            _c("a", { attrs: { href: "#2022-12-21/vehicleWarn/list" } }, [
              _vm._v("告警数据列表"),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("版本号：2022-12-21"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2023-02-01")]),
          _c("td", [
            _vm._v("接口 "),
            _c(
              "a",
              { attrs: { href: "#2022-12-21/taskRecord/trackCoordinate" } },
              [_vm._v("任务报告行驶轨迹坐标")]
            ),
            _vm._v(" 新增返回数据： 速度、电量百分比、车辆行驶方向"),
            _c("br"),
            _vm._v("接口 "),
            _c("a", { attrs: { href: "#2022-12-21/vehicleWarn/list" } }, [
              _vm._v("告警数据列表"),
            ]),
            _vm._v(" 新增返回数据：告警实例uuid"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2023-02-23")]),
          _c("td", [
            _vm._v("接口 "),
            _c("a", { attrs: { href: "#2022-12-21/vehicleWarn/list" } }, [
              _vm._v("告警数据列表"),
            ]),
            _vm._v(
              "修改返回值 : warnStatus不再使用，增加清除告警时间clearTime"
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2023-03-07")]),
          _c("td", [
            _c("a", { attrs: { href: "#2021-11-22/vehicleStatus" } }, [
              _vm._v("车辆状态"),
            ]),
            _vm._v("、"),
            _c("a", { attrs: { href: "#2022-06-20/vehicleLocation" } }, [
              _vm._v("获取车辆实时位置"),
            ]),
            _vm._v("、"),
            _c(
              "a",
              { attrs: { href: "#2022-12-21/taskRecord/trackCoordinate" } },
              [_vm._v("任务报告行驶轨迹坐标")]
            ),
            _vm._v(" 等3个接口中涉及的speed参数值单位都统一为 m/s"),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2023-06-01")]),
          _c("td", [
            _c("a", { attrs: { href: "#2022-12-21/taskRecord/list" } }, [
              _vm._v("任务报告列表"),
            ]),
            _vm._v(
              "接口增加关于任务次数的tasksExeNumberDay和tasksNumberDay字段，更正state字段描述；增加“名词解释”内容"
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("2023-06-25")]),
          _c("td", [
            _vm._v("1. 升级接口限流功能，增加各账号车辆相关接口访问次数；"),
            _c("br"),
            _vm._v("2. 接口"),
            _c("a", { attrs: { href: "#2021-11-22/vehicleList" } }, [
              _vm._v("车辆列表"),
            ]),
            _vm._v(
              "的响应数据变量名artificialDrivingTime改为controlDrivingTime，automaticTotalDistance改为controlTotalDistance;"
            ),
            _c("br"),
            _vm._v("3. 接口"),
            _c("a", { attrs: { href: "#2021-11-22/vehicleStatus" } }, [
              _vm._v("车辆状态"),
            ]),
            _vm._v("支持返回车辆历史智驾行驶总里程和时长数据；"),
            _c("br"),
            _vm._v("4. 接口"),
            _c("a", { attrs: { href: "#2021-11-22/vehicleStatus" } }, [
              _vm._v("车辆状态"),
            ]),
            _vm._v(
              "的响应数据变量名odo改为odom，autoOdo改为autoOdom，controlOdo改为controlOdom，odoTime改为odomTime；"
            ),
            _c("br"),
            _vm._v("5. 其他若干问题修复。"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_vm._v("概述 "), _c("a", { attrs: { name: "summary" } })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _vm._v("名词解释"),
      _c("a", { attrs: { name: "name-description" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("名称")]), _c("th", [_vm._v("说明")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("任务")]),
      _c("td", [_vm._v("车辆作业任务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _vm._v("接口参考说明"),
      _c("a", { attrs: { name: "api-reference" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("登录验证服务"),
      _c("a", { attrs: { name: "1-sso" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("a", { attrs: { name: "11-token" } })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Path：")]),
      _vm._v("{api_url}/openapi/token"),
      _c("br"),
      _c("strong", [_vm._v("Method:")]),
      _vm._v(" POST"),
      _c("br"),
      _c("strong", [_vm._v("Content-type:")]),
      _vm._v(" application/json"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("名称")]),
          _c("th", [_vm._v("位置")]),
          _c("th", [_vm._v("类型")]),
          _c("th", [_vm._v("必选")]),
          _c("th", [_vm._v("说明")]),
        ]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v("» clientId")]),
          _c("td", [_vm._v("body")]),
          _c("td", [_vm._v("string")]),
          _c("td", [_vm._v("true")]),
          _c("td", [_vm._v("Client Id")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("» clientSecret")]),
          _c("td", [_vm._v("body")]),
          _c("td", [_vm._v("string")]),
          _c("td", [_vm._v("true")]),
          _c("td", [_vm._v("Client密钥")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("blockquote", [_c("p", [_vm._v("请求参数示例")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { pre: true }, [
      _c(
        "code",
        { pre: true, attrs: { "v-pre": "", class: "language-json" } },
        [
          _vm._v("{\n    "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"clientId"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"xxxxxxxxxxxxxxxxxx"'),
          ]),
          _vm._v(",\n    "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"clientSecret"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'),
          ]),
          _vm._v("\n}\n"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("blockquote", [_c("p", [_vm._v("返回示例")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("blockquote", [_c("p", [_vm._v("成功")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { pre: true }, [
      _c(
        "code",
        { pre: true, attrs: { "v-pre": "", class: "language-json" } },
        [
          _vm._v("{\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"msg"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"操作成功"'),
          ]),
          _vm._v(",\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"code"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"200"'),
          ]),
          _vm._v(",\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"token"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v(
              '"eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUwODkxOTkwLWM3OTctNDkwYi1hNzMxLWMyMzZkZjk2ZmI0YSJ9.QjYqwuTJ6iXJzZyjLyAmN-DLysYe3pTvRr-9PF41t4PVkqKMF6G28sqwAVX0lh-W4k54mSCn0Xm-B4c0lO6wnQ"'
            ),
          ]),
          _vm._v("\n}\n"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("blockquote", [_c("p", [_vm._v("失败")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { pre: true }, [
      _c(
        "code",
        { pre: true, attrs: { "v-pre": "", class: "language-json" } },
        [
          _vm._v("{\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"msg"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"illegal IP address"'),
          ]),
          _vm._v(",\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"code"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
            _vm._v("403"),
          ]),
          _vm._v("\n}\n"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { pre: true }, [
      _c(
        "code",
        { pre: true, attrs: { "v-pre": "", class: "language-json" } },
        [
          _vm._v("{\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"msg"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"not found client"'),
          ]),
          _vm._v(",\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"code"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
            _vm._v("404"),
          ]),
          _vm._v("\n}\n"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { pre: true }, [
      _c(
        "code",
        { pre: true, attrs: { "v-pre": "", class: "language-json" } },
        [
          _vm._v("{\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"msg"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"illegal clientSecret"'),
          ]),
          _vm._v(",\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"code"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
            _vm._v("401"),
          ]),
          _vm._v("\n}\n"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { pre: true }, [
      _c(
        "code",
        { pre: true, attrs: { "v-pre": "", class: "language-json" } },
        [
          _vm._v("{\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"msg"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"illegal clientId"'),
          ]),
          _vm._v(",\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"code"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
            _vm._v("400"),
          ]),
          _vm._v("\n}\n"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("pre", { pre: true }, [
      _c(
        "code",
        { pre: true, attrs: { "v-pre": "", class: "language-json" } },
        [
          _vm._v("{\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"msg"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
            _vm._v('"out of generate token limit"'),
          ]),
          _vm._v(",\n  "),
          _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
            _vm._v('"code"'),
          ]),
          _vm._v(": "),
          _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
            _vm._v("406"),
          ]),
          _vm._v("\n}\n"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("状态码")]),
          _c("th", [_vm._v("状态码含义")]),
          _c("th", [_vm._v("说明")]),
          _c("th", [_vm._v("数据模型")]),
        ]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v("200")]),
          _c("td", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://tools.ietf.org/html/rfc7231#section-6.3.1",
                },
              },
              [_vm._v("OK")]
            ),
          ]),
          _c("td", [_vm._v("成功")]),
          _c("td", [_vm._v("Inline")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("状态码 "), _c("strong", [_vm._v("200")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("名称")]),
          _c("th", [_vm._v("类型")]),
          _c("th", [_vm._v("必选")]),
          _c("th", [_vm._v("约束")]),
          _c("th", [_vm._v("说明")]),
        ]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v("» msg")]),
          _c("td", [_vm._v("string")]),
          _c("td", [_vm._v("true")]),
          _c("td", [_vm._v("none")]),
          _c("td", [_vm._v("接口消息")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("» code")]),
          _c("td", [_vm._v("integer")]),
          _c("td", [_vm._v("true")]),
          _c("td", [_vm._v("none")]),
          _c("td", [_vm._v("接口状态码")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("» token")]),
          _c("td", [_vm._v("string")]),
          _c("td", [_vm._v("false")]),
          _c("td", [_vm._v("none")]),
          _c("td", [_vm._v("token")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("h3", [
        _c("strong", [_vm._v("验证token")]),
        _c("a", { attrs: { name: "12-authRedirect" } }),
      ]),
      _c("p", [
        _vm._v(
          "用于验证token是否有效，有效则自动登入，并跳转到首页，无效则跳回指定网址。"
        ),
        _c("br"),
        _vm._v(" （登入后，当点击“登出”功能链接后，也会自动跳转到指定网址）"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("Path：")]),
        _vm._v("{api_url}/openapi/authRedirect"),
        _c("br"),
        _c("strong", [_vm._v("Method:")]),
        _vm._v(" GET"),
      ]),
      _c("h4", [_vm._v("请求参数")]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("名称")]),
            _c("th", [_vm._v("位置")]),
            _c("th", [_vm._v("类型")]),
            _c("th", [_vm._v("必选")]),
            _c("th", [_vm._v("说明")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("» token")]),
            _c("td", [_vm._v("params")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("true")]),
            _c("td", [_vm._v("token值")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("» callback")]),
            _c("td", [_vm._v("params")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("true")]),
            _c("td", [
              _vm._v(
                "验证失败后或登出系统后要跳回的网址（网址不支持带params参数）"
              ),
            ]),
          ]),
        ]),
      ]),
      _c("blockquote", [_c("p", [_vm._v("请求参数示例")])]),
      _c("pre", { pre: true }, [
        _c("code", { pre: true, attrs: { "v-pre": "" } }, [
          _vm._v(
            "{api_url}/openapi/authRedirect?token=eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUwODkxOTkwLWM3OTctNDkwYi1hNzMxLWMyMzZkZjk2ZmI0YSJ9.QjYqwuTJ6iXJzZyjLyAmN-DLysYe3pTvRr-9PF41t4PVkqKMF6G28sqwAVX0lh-W4k54mSCn0Xm-B4c0lO6wnQ&callback=https://www.example.com/login\n"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("h2", [
        _vm._v("接口验证说明"),
        _c("a", { attrs: { name: "2-other-api" } }),
      ]),
      _c("p", [
        _c("strong", [
          _vm._v(
            "在http请求头中，每个api的访问都需要携带 authorization : Bearer Token验证访问授权。"
          ),
        ]),
      ]),
      _c("p", [_vm._v("另外，每个对外接口都需要带上以下公共安全控管参数：")]),
      _c("ul", [
        _c("li", [
          _c("strong", [_vm._v("version")]),
          _vm._v(
            ": 版本号，以yyyy-MM-dd日期格式作为版本号管理，具体值为每个接口地址内的日期字符串，比如接口"
          ),
          _c("code", { pre: true }, [
            _vm._v("/api/open/2021-11-22/vehicleList"),
          ]),
          _vm._v("的version值为"),
          _c("code", { pre: true }, [_vm._v("2021-11-22")]),
        ]),
        _c("li", [
          _c("strong", [_vm._v("clientId")]),
          _vm._v(": 租户clientId"),
        ]),
        _c("li", [
          _c("strong", [_vm._v("timestamp")]),
          _vm._v(
            ": 时间戳，日期格式按照 ISO8601 标准表示，并需要使用 UTC 时间。 格式：YYYY-MM-DDThh:mm:ssZ。 例如：2014-05-26T12:00:00Z（为北京时间 2014 年 5 月 26 日 20 点 0 分 0 秒）"
          ),
        ]),
        _c("li", [
          _c("strong", [_vm._v("signature")]),
          _vm._v(": sha256签名值，生成规则为： "),
          _c("code", { pre: true }, [
            _vm._v(
              "sha256(URLEncoder(字典排序(公共参数名称=公共参数值&接口必填参数名=接口必填参数值)) + apiSecret)"
            ),
          ]),
          _c("blockquote", [
            _c("p", [
              _vm._v("其中"),
              _c("strong", [_vm._v("apiSecret")]),
              _vm._v(
                '需登录租户平台内的“开发管理”>"我的开发者信息"页面内的“API Secret”进行生成。'
              ),
              _c("br"),
              _vm._v(" 每个签名的有效期为60秒。"),
            ]),
          ]),
        ]),
      ]),
      _c("p", [_vm._v("举例:")]),
      _c("blockquote", [
        _c("p", [
          _vm._v(
            "apiSecret值为：fed66584346d1ea3df09c6ae2f7e4b63a3190e6ac7f65e1d0a90f2bf831de956"
          ),
          _c("br"),
          _vm._v(
            " 拼接参数为：clientId=test&timestamp=2022-12-09T12:27:03.094Z&vehicleId=test01&version=2021-11-22"
          ),
          _c("br"),
          _vm._v(
            " 那么生成的signature为：9f0fc6a7083740f8a8c3e7a76ea6d161e8ff8ed255e7d978550f2727a8d7bee0"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("h2", [
        _vm._v("车辆信息接口"),
        _c("a", { attrs: { name: "api-reference-vehicle" } }),
      ]),
      _c("ol", [
        _c("li", [
          _c("h3", [
            _vm._v("车辆列表"),
            _c("a", { attrs: { name: "2021-11-22/vehicleList" } }),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口说明")])]),
          _c("p", [_vm._v("说明：以列表结构数据形式获取若干辆车的信息。")]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _c("strong", [
                    _vm._v("{api_url}/api/open/2021-11-22/vehicleList"),
                  ]),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"ff8abdf411e9142bc7019c24776f95d39eca4cc1fcfbf06c72bbc478c6377764"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：无")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("V8888")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("车牌号")]),
                _c("td", [_vm._v("licensePlateNum")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("00001")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("车辆名称")]),
                _c("td", [_vm._v("name")]),
                _c("td", [_vm._v("stirng")]),
                _c("td", [_vm._v("xx1号")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("所属机构")]),
                _c("td", [_vm._v("deptName")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("xxx机扫部")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("车辆类别")]),
                _c("td", [_vm._v("vehicleType")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("自动扫地车")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("车辆状态")]),
                _c("td", [_vm._v("status")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [_vm._v("1正常，0下架")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日行驶时长(自动驾驶)")]),
                _c("td", [_vm._v("automaticDrivingTime")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [_vm._v("当日数据，单位：秒")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日行驶时长(遥控驾驶)")]),
                _c("td", [_vm._v("controlDrivingTime")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [
                  _vm._v(
                    "当日数据，单位：秒。（原变量名artificialDrivingTime将弃用）"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日行驶总距离(自动驾驶)")]),
                _c("td", [_vm._v("automaticTotalDistance")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [_vm._v("当日数据，单位：米")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日行驶总距离(遥控驾驶)")]),
                _c("td", [_vm._v("controlTotalDistance")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [
                  _vm._v(
                    "当日数据，单位：米 （原变量名artificialTotalDistance将弃用）"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{  \n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"查询成功"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"total"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(":{\n    [{\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"licensePlateNum"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"00001"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"name"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xx1号"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"deptName"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxx机扫部"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleType"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"自动扫地车"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"automaticDrivingTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("9000"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlDrivingTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1000"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"automaticTotalDistance"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("999"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlTotalDistance"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("888"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"status"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v("\n    },\n    {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8889"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"licensePlateNum"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"00002"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"name"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xx2号"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"deptName"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxx机扫部"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleType"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"自动扫地车"'),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"automaticDrivingTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("9000"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlDrivingTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1000"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"automaticTotalDistance"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("999"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlTotalDistance"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("888"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"status"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v("\n    }]\n\t}\n}\n"),
              ]
            ),
          ]),
        ]),
        _c("li", [
          _c("h3", [
            _vm._v("车辆状态"),
            _c("a", { attrs: { name: "2021-11-22/vehicleStatus" } }),
          ]),
          _c("p", [
            _vm._v(
              "说明：可获取的信息包含车辆编号、在线状态、作业状态、电量、行驶里程、速度、行驶时长等信息。"
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _c("strong", [
                    _vm._v("{api_url}/api/open/2021-11-22/vehicleStatus"),
                  ]),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：vehicleId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("V8888")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("在线状态")]),
                _c("td", [_vm._v("online")]),
                _c("td", [_vm._v("Boolean")]),
                _c("td", [_vm._v("True")]),
                _c("td", [
                  _vm._v(
                    "true在线，false离线。（注意：此字段表示车辆当前的在线状态，并非gpsTime时间点的在线状态）"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("作业状态")]),
                _c("td", [_vm._v("workStatus")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [
                  _vm._v(
                    "0 空闲中 1 地图切换中 2 任务创建中 3 任务启动处理中 4 任务运行中"
                  ),
                  _c("br"),
                  _vm._v(
                    "5 任务暂停处理中 6 任务暂停中 7 任务停止处理中 8 任务恢复处理中"
                  ),
                  _c("br"),
                  _vm._v("9 任务列表获取中 -1 未知状态"),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("电量")]),
                _c("td", [_vm._v("soc")]),
                _c("td", [_vm._v("Double")]),
                _c("td", [_vm._v("50")]),
                _c("td", [_vm._v("比如数值50，表示剩余电量50%")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日总里程")]),
                _c("td", [_vm._v("odom")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1234")]),
                _c("td", [_vm._v("单位：米。（原变量名odo将弃用）")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日智驾里程")]),
                _c("td", [_vm._v("autoOdom")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("200")]),
                _c("td", [_vm._v("单位：米。（原变量名autoOdo将弃用）")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日遥控里程")]),
                _c("td", [_vm._v("controlOdom")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("10")]),
                _c("td", [_vm._v("单位：米。（原变量名controlOdo将弃用）")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("速度")]),
                _c("td", [_vm._v("speed")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("0.7777")]),
                _c("td", [_vm._v("单位：米/秒。")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("最近一次点位上报时间")]),
                _c("td", [_vm._v("gpsTime")]),
                _c("td", [_vm._v("datetime")]),
                _c("td", [_vm._v("2021-07-3-3 14:33:21")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日智驾时长")]),
                _c("td", [_vm._v("autoTime")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("60")]),
                _c("td", [_vm._v("单位：秒")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日遥控时长")]),
                _c("td", [_vm._v("controlTime")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("70")]),
                _c("td", [_vm._v("单位：秒")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("今日总时长")]),
                _c("td", [_vm._v("odomTime")]),
                _c("td", [_vm._v("Int")]),
                _c("td", [_vm._v("60")]),
                _c("td", [_vm._v("单位：秒。（原变量名odoTime将弃用）")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("控制模式")]),
                _c("td", [_vm._v("controlMode")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [
                  _vm._v(
                    "-1:未知模式 0:人工(手动)模式 1:自动模式:智驾模式 2:自动模式:遥控模式"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("历史智驾总里程")]),
                _c("td", [_vm._v("autoOdomTotal")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [
                  _vm._v(
                    "单位：米。历史以来自动驾驶模式下的行驶总里程。（不包含今日数据）"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("历史智驾总时长")]),
                _c("td", [_vm._v("autoTimeTotal")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [
                  _vm._v(
                    "单位：秒。历史以来自动驾驶模式下的行驶总时长。（不包含今日数据）"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("历史遥控总里程")]),
                _c("td", [_vm._v("controlOdomTotal")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [
                  _vm._v(
                    "单位：米。历史以来遥控模式下的行驶总里程。（不包含今日数据）"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("历史遥控总时长")]),
                _c("td", [_vm._v("controlTimeTotal")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [
                  _vm._v(
                    "单位：秒。历史以来遥控模式下的行驶总时长。（不包含今日数据）"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("blockquote", [
            _c("p", [
              _vm._v(
                "注：历史智驾总里程、历史智驾总时长、历史遥控总里程、历史遥控总时长，不包含今日数据。如要计算当前实时总里程或总时长，需将此接口的对应历史总里程和今日里程相加，对应历史总时长和今日时长相加。"
              ),
              _c("br"),
              _vm._v(
                " 例如：历史智驾总里程为1000，今日智驾里程为200，则当前实时总里程为1200。历史智驾总时长为1000，今日智驾时长为200，则当前实时总时长为1200。"
              ),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"查询成功"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"online"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"workStatus"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"soc"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("55"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"odom"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10000"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"odomTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3600"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"autoTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2400"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"autoOdom"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8000"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlOdom"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2000"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1200"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"autoOdomTotal"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("30702"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"autoTimeTotal"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("86173"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlOdomTotal"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("31"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlTimeTotal"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1388"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"speed"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.7777"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"controlMode"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"gpsTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15 18:00:51"'),
                ]),
                _vm._v("\n  }\n}\n"),
              ]
            ),
          ]),
        ]),
        _c("li", [
          _c("h3", [
            _vm._v("车辆行驶数据日统计 "),
            _c("a", { attrs: { name: "2021-11-22/dailyStatistics" } }),
          ]),
          _c("p", [
            _vm._v(
              "说明：可查询指定车辆的历史行驶日统计数据，包含行驶里程、时长等。"
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2021-11-22/dailyStatistics"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("需统计日期")]),
                _c("td", [_vm._v("summaryDate")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td", [_vm._v("格式yyyy-MM-dd")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"summaryDate"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：vehicleId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("200:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("String")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("data数据说明")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("智驾里程")]),
                _c("td", [_vm._v("autoCtlOdom")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [
                  _vm._v(
                    "-1 表示没有数据; 自动行驶里程（目前统计的是空驶、清扫行驶） 单位:米"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("遥控里程")]),
                _c("td", [_vm._v("remoteCtlOdom")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [
                  _vm._v(
                    "-1 表示没有数据; 由app或云端遥控下行驶里程（含空驶、清扫行驶等） 单位:米"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("智驾时长")]),
                _c("td", [_vm._v("autoCtlTime")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("-1 表示没有数据; 单位:秒")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("遥控时长")]),
                _c("td", [_vm._v("remotecCtlTime")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("-1 表示没有数据; 单位:秒")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("总里程")]),
                _c("td", [_vm._v("odo")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("-1 表示没有数据; 智驾 + 遥控里程 单位:米")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("总时长")]),
                _c("td", [_vm._v("ctlTime")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [
                  _vm._v("-1 表示没有数据; 智驾时长 + 遥控时长 单位:米"),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("查询日期")]),
                _c("td", [_vm._v("summaryDate")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"查询成功"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(":{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"autoCtlOdom"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"remoteCtlOdom"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"autoCtlTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"remotecCtlTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"odo"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"ctlTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"summaryDate"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v("\n  }\n\n}\n"),
              ]
            ),
          ]),
          _c("p", [_c("a", { attrs: { name: "2022-06-20/vehicleLocation" } })]),
        ]),
        _c("li", [
          _c("h3", [_vm._v("获取车辆实时位置")]),
          _c("p", [
            _vm._v(
              "说明：用于获取指定车辆当前实时位置信息，包含位置经纬度、方向、速度、电量等信息。"
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2022-06-20/vehicleLocation"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2022-06-20T16:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2022-06-20"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：vehicleId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("200:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("json")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("h4", [_vm._v("数据说明")]),
          _c("blockquote", [
            _c("p", [
              _vm._v(
                "坐标系是***wgs84***坐标，需根据地图平台需要自行做坐标转换。如果有数据，30秒更新一次坐标点"
              ),
            ]),
          ]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("字段名")]),
                _c("th", [_vm._v("变量名")]),
                _c("th", [_vm._v("类型")]),
                _c("th", [_vm._v("举例")]),
                _c("th", [_vm._v("说明")]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("V8888")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("经度")]),
                _c("td", [_vm._v("longitude")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("80.24551754085816")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("纬度")]),
                _c("td", [_vm._v("latitude")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("41.17359587807032")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("经纬度上报时间")]),
                _c("td", [_vm._v("time")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("2022-06-22 12:13:35")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("前车角度(方向)")]),
                _c("td", [_vm._v("direction")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("170")]),
                _c("td", [_vm._v("[0,360）前车的角度heading是逆负顺正")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("速度")]),
                _c("td", [_vm._v("speed")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("0.7777")]),
                _c("td", [_vm._v("单位：m/s 0.7777m/s")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("电量")]),
                _c("td", [_vm._v("soc")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("50.3")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"获取位置信息成功"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": {\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"longitude"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("80.24551754085816"),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"latitude"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("41.17359587807032"),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"time"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2022-06-22 12:13:35"'),
                ]),
                _vm._v(",\n       "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"direction"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("170"),
                ]),
                _vm._v(",\n       "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"speed"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.77777777"),
                ]),
                _vm._v(",\n       "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"soc"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("50.3"),
                ]),
                _vm._v("\n    }\n}\n"),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("h2", [
        _vm._v("视频信息接口"),
        _c("a", { attrs: { name: "api-reference-vedio" } }),
      ]),
      _c("ol", [
        _c("li", [
          _c("h3", [
            _vm._v("查询指定时间范围内的主摄像头历史视频记录(deprecated)"),
            _c("a", { attrs: { name: "2021-11-22/videoHistoryQuery" } }),
          ]),
          _c("p", [
            _vm._v(
              "说明：用于查询指定时间范围内指定车辆的主摄像头历史视频记录。"
            ),
          ]),
          _c("blockquote", [
            _c("p", [
              _vm._v("此接口后续将弃用，建议用"),
              _c("a", { attrs: { href: "#2022-06-20/videoHistoryQuery" } }, [
                _vm._v("4.2"),
              ]),
              _vm._v("接口替代"),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2021-11-22/videoHistoryQuery"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("开始时间")]),
                _c("td", [_vm._v("startTime")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("结束时间")]),
                _c("td", [_vm._v("endTime")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("页码")]),
                _c("td", [_vm._v("pageNum")]),
                _c("td", [_vm._v("Long")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("每页数据条数")]),
                _c("td", [_vm._v("pageSize")]),
                _c("td", [_vm._v("Long")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"startTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T10:34:58+08:00"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"endTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-12-23T10:34:58+08:00"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10"),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：vehicleId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("0:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("object")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"查询成功"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageCount"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"totalCount"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"records"'),
                ]),
                _vm._v(":[\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"id"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"6334ed2d-76fd-49ba-b1e8-d935a875879c"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"startTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T13:58:41+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"endTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T14:10:30+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"url"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio2.revolution.auto/record%2Flive%2F33987654566654334565454343%2F2021-11-22-13-58-51_2021-11-22-14-10-30.mp4?Expires=1637658625&OSSAccessKeyId=STS.NTacTRX21dHLZT794pgY9LrDK&Signature=djsJc1Eh5db%2Bt3VpDSypiZLA1k4%3D&security-token=CAISlwJ1q6Ft5B2yfSjIr5fUKO7mte0Q04qnWHKG3TQla9YVo7fvqTz2IHFIeXFvA%2B0cv%2F81nWFU6vsYlokiYs9ob2foM45bt8x7tF%2BMPtWEkebksvla2bH1QTHLIUYgDHdb36KrIunGc9KBNnrm9EYqs5aYGBymW1u6S%2B7r7bdsctUQWCShcDNCH604DwB%2BqcgcRxCzXLTXRXyMuGfLC1dysQdRkH527b%2FFoveR8R3Dllb3uIR3zsbTWsH5NJM1ZMsuDInqg7ItLPv7vXQOu0QQxsBfl7dZ%2FDrLhNaZDmRK7g%2BOW%2BiuqYwzcVcmPPVgSvUe9amgyaAioJz6k4Xm1hBzBsheWCnEX4Gmz%2Bz%2FI9muMtsp0zEA7Z8JbLoagAFHd%2FlacfOSNLkr3msDqVSB%2BhzNOl1Zu4t3NJ3YNvmLHCHloMrJbz6kgbeyQdgQSnw6ge4BrYDrlv3b3KCJwGYADJ6Jki%2FMjnDtLIJTMA7aY0fhCmVxqUFDtVG%2BOd2CFzeFo5FzOl88prom37Cnuazb7rXJEovO1Ms%2Fr5HrMPDJEA%3D%3D"'
                  ),
                ]),
                _vm._v("\n        },\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"id"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"a63086c4-2f2b-44a7-80d6-15495d9a0bfa"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"startTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T13:43:49+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"endTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T13:58:41+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"url"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio2.revolution.auto/record%2Flive%2F33987654566654334565454343%2F2021-11-22-13-43-51_2021-11-22-13-58-51.mp4?Expires=1637658625&OSSAccessKeyId=STS.NTacTRX21dHLZT794pgY9LrDK&Signature=eOTndkjH8HO5K8BQuqWzC2453rA%3D&security-token=CAISlwJ1q6Ft5B2yfSjIr5fUKO7mte0Q04qnWHKG3TQla9YVo7fvqTz2IHFIeXFvA%2B0cv%2F81nWFU6vsYlokiYs9ob2foM45bt8x7tF%2BMPtWEkebksvla2bH1QTHLIUYgDHdb36KrIunGc9KBNnrm9EYqs5aYGBymW1u6S%2B7r7bdsctUQWCShcDNCH604DwB%2BqcgcRxCzXLTXRXyMuGfLC1dysQdRkH527b%2FFoveR8R3Dllb3uIR3zsbTWsH5NJM1ZMsuDInqg7ItLPv7vXQOu0QQxsBfl7dZ%2FDrLhNaZDmRK7g%2BOW%2BiuqYwzcVcmPPVgSvUe9amgyaAioJz6k4Xm1hBzBsheWCnEX4Gmz%2Bz%2FI9muMtsp0zEA7Z8JbLoagAFHd%2FlacfOSNLkr3msDqVSB%2BhzNOl1Zu4t3NJ3YNvmLHCHloMrJbz6kgbeyQdgQSnw6ge4BrYDrlv3b3KCJwGYADJ6Jki%2FMjnDtLIJTMA7aY0fhCmVxqUFDtVG%2BOd2CFzeFo5FzOl88prom37Cnuazb7rXJEovO1Ms%2Fr5HrMPDJEA%3D%3D"'
                  ),
                ]),
                _vm._v("\n        }\n    ]\n}\n}\n"),
              ]
            ),
          ]),
        ]),
        _c("li", [
          _c("h3", [
            _vm._v("查询指定时间范围内指定摄像头的历史视频记录"),
            _c("a", { attrs: { name: "2022-06-20/videoHistoryQuery" } }),
          ]),
          _c("p", [
            _vm._v("说明：用于查询指定时间内指定车辆摄像头的历史视频记录地址"),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2022-06-20/videoHistoryQuery"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("视频流ID")])]),
                _c("td", [_c("strong", [_vm._v("streamId")])]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("开始时间")]),
                _c("td", [_vm._v("startTime")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("结束时间")]),
                _c("td", [_vm._v("endTime")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("页码")]),
                _c("td", [_vm._v("pageNum")]),
                _c("td", [_vm._v("Long")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("每页数据条数")]),
                _c("td", [_vm._v("pageSize")]),
                _c("td", [_vm._v("Long")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"streamId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"yxyxyxyxyxyxyxy"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"startTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T10:34:58+08:00"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"endTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-12-23T10:34:58+08:00"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10"),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：streamId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("0:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("object")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"查询成功"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageCount"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"totalCount"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"records"'),
                ]),
                _vm._v(":[\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"id"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"6334ed2d-76fd-49ba-b1e8-d935a875879c"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"startTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T13:58:41+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"endTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T14:10:30+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"url"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio2.revolution.auto/record%2Flive%2F33987654566654334565454343%2F2021-11-22-13-58-51_2021-11-22-14-10-30.mp4?Expires=1637658625&OSSAccessKeyId=STS.NTacTRX21dHLZT794pgY9LrDK&Signature=djsJc1Eh5db%2Bt3VpDSypiZLA1k4%3D&security-token=CAISlwJ1q6Ft5B2yfSjIr5fUKO7mte0Q04qnWHKG3TQla9YVo7fvqTz2IHFIeXFvA%2B0cv%2F81nWFU6vsYlokiYs9ob2foM45bt8x7tF%2BMPtWEkebksvla2bH1QTHLIUYgDHdb36KrIunGc9KBNnrm9EYqs5aYGBymW1u6S%2B7r7bdsctUQWCShcDNCH604DwB%2BqcgcRxCzXLTXRXyMuGfLC1dysQdRkH527b%2FFoveR8R3Dllb3uIR3zsbTWsH5NJM1ZMsuDInqg7ItLPv7vXQOu0QQxsBfl7dZ%2FDrLhNaZDmRK7g%2BOW%2BiuqYwzcVcmPPVgSvUe9amgyaAioJz6k4Xm1hBzBsheWCnEX4Gmz%2Bz%2FI9muMtsp0zEA7Z8JbLoagAFHd%2FlacfOSNLkr3msDqVSB%2BhzNOl1Zu4t3NJ3YNvmLHCHloMrJbz6kgbeyQdgQSnw6ge4BrYDrlv3b3KCJwGYADJ6Jki%2FMjnDtLIJTMA7aY0fhCmVxqUFDtVG%2BOd2CFzeFo5FzOl88prom37Cnuazb7rXJEovO1Ms%2Fr5HrMPDJEA%3D%3D"'
                  ),
                ]),
                _vm._v("\n        },\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"id"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"a63086c4-2f2b-44a7-80d6-15495d9a0bfa"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"startTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T13:43:49+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"endTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22T13:58:41+08:00"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"url"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio2.revolution.auto/record%2Flive%2F33987654566654334565454343%2F2021-11-22-13-43-51_2021-11-22-13-58-51.mp4?Expires=1637658625&OSSAccessKeyId=STS.NTacTRX21dHLZT794pgY9LrDK&Signature=eOTndkjH8HO5K8BQuqWzC2453rA%3D&security-token=CAISlwJ1q6Ft5B2yfSjIr5fUKO7mte0Q04qnWHKG3TQla9YVo7fvqTz2IHFIeXFvA%2B0cv%2F81nWFU6vsYlokiYs9ob2foM45bt8x7tF%2BMPtWEkebksvla2bH1QTHLIUYgDHdb36KrIunGc9KBNnrm9EYqs5aYGBymW1u6S%2B7r7bdsctUQWCShcDNCH604DwB%2BqcgcRxCzXLTXRXyMuGfLC1dysQdRkH527b%2FFoveR8R3Dllb3uIR3zsbTWsH5NJM1ZMsuDInqg7ItLPv7vXQOu0QQxsBfl7dZ%2FDrLhNaZDmRK7g%2BOW%2BiuqYwzcVcmPPVgSvUe9amgyaAioJz6k4Xm1hBzBsheWCnEX4Gmz%2Bz%2FI9muMtsp0zEA7Z8JbLoagAFHd%2FlacfOSNLkr3msDqVSB%2BhzNOl1Zu4t3NJ3YNvmLHCHloMrJbz6kgbeyQdgQSnw6ge4BrYDrlv3b3KCJwGYADJ6Jki%2FMjnDtLIJTMA7aY0fhCmVxqUFDtVG%2BOd2CFzeFo5FzOl88prom37Cnuazb7rXJEovO1Ms%2Fr5HrMPDJEA%3D%3D"'
                  ),
                ]),
                _vm._v("\n        }\n    ]\n}\n}\n"),
              ]
            ),
          ]),
        ]),
        _c("li", [
          _c("h3", [
            _vm._v("获取车辆上的主摄像头实时视频播放地址(deprecated)"),
            _c("a", { attrs: { name: "2021-11-22/realTimeVideoUrl" } }),
          ]),
          _c("p", [
            _vm._v("说明：用于获取指定车辆上的主摄像头实时视频播放地址。"),
          ]),
          _c("blockquote", [
            _c("blockquote", [
              _c("p", [
                _vm._v("此接口后续将弃用，建议用"),
                _c("a", { attrs: { href: "#2022-06-20/realTimeVideoUrl" } }, [
                  _vm._v("4.4"),
                ]),
                _vm._v("接口替代"),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2021-11-22/realTimeVideoUrl"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：vehicleId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("0:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("String")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"获取地址成功"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio.revolution.auto/live/3234567876587656765.flv?auth_key=1637657990-0-0-1302e91578ef837c8eb3c6e26db303ac"'
                  ),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
        ]),
        _c("li", [
          _c("h3", [
            _vm._v("获取车辆各摄像头的实时视频播放地址"),
            _c("a", { attrs: { name: "2022-06-20/realTimeVideoUrl" } }),
          ]),
          _c("p", [
            _vm._v(
              "说明：获取车辆上各个摄像头的实时视频播放地址，如果有多个摄像头则返回多个摄像头播放地址。"
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2022-06-20/realTimeVideoUrl"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2022-06-20T16:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2022-06-20"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：vehicleId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("0:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("array")]),
                _c("td"),
                _c("td", [_vm._v("数组，相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("h4", [_vm._v("数据说明")]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("摄像头类型")]),
                _c("td", [_vm._v("main")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("1")]),
                _c("td", [_vm._v("1 主摄像头 0 其他摄像头")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("摄像头名称")]),
                _c("td", [_vm._v("name")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("主摄像头")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("摄像头视频流ID")]),
                _c("td", [_vm._v("streamId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("323456787658765")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("播放地址")]),
                _c("td", [_vm._v("addr")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td", [_vm._v("播放地址")]),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"获取地址成功"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": [\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"main"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",   "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("// 1 表示主摄像头"),
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"name"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"主摄像头"'),
                ]),
                _vm._v(",  "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("// 前摄像头"),
                ]),
                _vm._v("\n           "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"streamId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"sssssssssssssss"'),
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//视频流Id"),
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"addr"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio.revolution.auto/live/3234567876587656765.flv?auth_key=1637657990-0-0-1302e91578ef837c8eb3c6e26db303ac"'
                  ),
                ]),
                _vm._v("\n        },\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"main"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"name"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"后"'),
                ]),
                _vm._v(",\n          "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"streamId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"ssssssssssssaaaaa"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"addr"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio.revolution.auto/live/3234567876587656765.flv?auth_key=1637657990-0-0-1302e91578ef837c8eb3c6e26db303ac"'
                  ),
                ]),
                _vm._v("\n        },\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"main"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"name"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"右"'),
                ]),
                _vm._v(",\n          "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"streamId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"ssssssssssswwwww"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"addr"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio.revolution.auto/live/3234567876587656765.flv?auth_key=1637657990-0-0-1302e91578ef837c8eb3c6e26db303ac"'
                  ),
                ]),
                _vm._v("\n        },\n        {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"main"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"name"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"左"'),
                ]),
                _vm._v(",\n          "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"streamId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"ssssssssssttttt"'),
                ]),
                _vm._v(",\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"addr"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"https://vedio.revolution.auto/live/3234567876587656765.flv?auth_key=1637657990-0-0-1302e91578ef837c8eb3c6e26db303ac"'
                  ),
                ]),
                _vm._v("\n        }\n    ]\n}\n"),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("h2", [
        _vm._v("任务信息接口"),
        _c("a", { attrs: { name: "api-reference-task" } }),
      ]),
      _c("ol", [
        _c("li", [
          _c("h3", [
            _vm._v("任务报告列表"),
            _c("a", { attrs: { name: "2022-12-21/taskRecord/list" } }),
          ]),
          _c("p", [_vm._v("说明：用于查询指定时间内车辆的任务报告")]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2022-12-21/taskRecord/list"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("否")]),
                _c("td", [_vm._v("为空则查所有车辆")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("查询时间")]),
                _c("td", [_vm._v("searchDate")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("否")]),
                _c("td", [
                  _vm._v(
                    "只能是具体某天的日期，格式： yyyy-MM-dd，为空则查最新的20条数据"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("页码")]),
                _c("td", [_vm._v("pageNum")]),
                _c("td", [_vm._v("Integer")]),
                _c("td", [_vm._v("是")]),
                _c("td", [_vm._v("1 2 3")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("每页数据条数")]),
                _c("td", [_vm._v("pageSize")]),
                _c("td", [_vm._v("Integer")]),
                _c("td", [_vm._v("是")]),
                _c("td", [_vm._v("最多20条每页")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"V8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"searchDate"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2023-01-03"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10"),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：vehicleId")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("200")]),
                _c("td", [_vm._v("200:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("object")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("p", [_vm._v("data数据说明")]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("总计条数")]),
                _c("td", [_vm._v("total")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("10")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("具体数据")]),
                _c("td", [_vm._v("rows")]),
                _c("td", [_vm._v("数组")]),
                _c("td"),
                _c("td", [_vm._v("具体数据")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("页码")]),
                _c("td", [_vm._v("pageNum")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("每页条数")]),
                _c("td", [_vm._v("pageSize")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_vm._v("rows数据说明")]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("任务id")]),
                _c("td", [_vm._v("taskExeId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [
                  _vm._v("task_execute_735a3060-4410-4ddc-8699-95175df1307a"),
                ]),
                _c("td", [
                  _vm._v("任务执行ID 只有在任务运行/任务暂停状态时才有"),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("V8888")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("任务名称")]),
                _c("td", [_vm._v("taskName")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("VehicleCreate")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("任务开始时刻")]),
                _c("td", [_vm._v("taskStartTime")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("任务结束时刻")]),
                _c("td", [_vm._v("taskEndTime")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("任务进度")]),
                _c("td", [_vm._v("taskProgress")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [
                  _vm._v("任务进度 [0,100] 只有在任务运行/任务暂停状态时才有"),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("车辆任务状态")]),
                _c("td", [_vm._v("state")]),
                _c("td", [_vm._v("Int")]),
                _c("td"),
                _c("td", [
                  _vm._v("0: 未开始 "),
                  _c("br"),
                  _vm._v("1: 暂停 "),
                  _c("br"),
                  _vm._v("2: 重新开始"),
                  _c("br"),
                  _vm._v("3: 停止"),
                  _c("br"),
                  _vm._v("4: 进行中"),
                  _c("br"),
                  _vm._v("5: 完成 "),
                  _c("br"),
                  _vm._v("6: 已终止 "),
                  _c("br"),
                  _vm._v("7: 异常终止 "),
                  _c("br"),
                  _vm._v("8: 已取消"),
                  _c("br"),
                  _vm._v("9: 规划异常结束"),
                  _c("br"),
                  _vm._v("10: 告警强制结束"),
                  _c("br"),
                  _vm._v("11: 被高等级任务中断而结束"),
                  _c("br"),
                  _vm._v("12: 等候执行"),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("任务类型")]),
                _c("td", [_vm._v("taskType")]),
                _c("td", [_vm._v("Int")]),
                _c("td"),
                _c("td", [_vm._v("0基于偱迹清扫、1基于LaneID")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("任务完成里程(单位：米)")]),
                _c("td", [_vm._v("finishedOdom")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [_vm._v("车辆异常后，可能没有数据，无法生成报告。")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("任务完成时长(单位：秒)")]),
                _c("td", [_vm._v("finishedTime")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [_vm._v("车辆异常后，可能没有数据，无法生成报告。")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("当天执行的任务次数")]),
                _c("td", [_vm._v("tasksExeNumberDay")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [_vm._v("车辆启动任务后算一次。")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("当天完成的任务次数")]),
                _c("td", [_vm._v("tasksNumberDay")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td", [
                  _vm._v(
                    "车辆完成任务后算一次。车辆异常或任务尚未完成时此字段无返回值且字段不显示。"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"操作成功"'),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(":{\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"total"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("15"),
                ]),
                _vm._v(",\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"rows"'),
                ]),
                _vm._v(":[\n            {\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskExeId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"task_execute_735a3060-4410-4ddc-8699-95175df1307a"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"v8888"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskName"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"VehicleCreate"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskStartTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Tue Jan 03 17:37:55 CST 2023"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskEndTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Tue Jan 03 17:44:10 CST 2023"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskProgress"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"state"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("4"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"finishedOdom"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6836"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"finishedTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("60"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskType"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"0"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"tasksExeNumberDay"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3"),
                ]),
                _vm._v("\n            },\n            {\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskExeId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"task_execute_735a3060-4410-4ddc-8699-95175df1307b"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"v8888"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskName"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"VehicleCreate"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskStartTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Tue Jan 03 17:16:53 CST 2023"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskEndTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Tue Jan 03 17:37:39 CST 2023"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskProgress"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("4"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"state"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"finishedOdom"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5755"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"finishedTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("45"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskType"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"0"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"tasksExeNumberDay"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"tasksNumberDay"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2"),
                ]),
                _vm._v("\n            },\n            {\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskExeId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"task_execute_735a3060-4410-4ddc-8699-95175df1307c"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"v8888"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskName"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"VehicleCreate"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskStartTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Tue Jan 03 16:36:22 CST 2023"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskEndTime"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Tue Jan 03 17:13:08 CST 2023"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskProgress"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("27"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"state"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"finishedOdom"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6766"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"finishedTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("56"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskType"'),
                ]),
                _vm._v(":"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"0"'),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"tasksExeNumberDay"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"tasksNumberDay"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v("\n            }\n        ]\n    }\n}\n\n\n"),
              ]
            ),
          ]),
        ]),
        _c("li", [
          _c("h3", [
            _vm._v("任务报告行驶轨迹坐标"),
            _c("a", {
              attrs: { name: "2022-12-21/taskRecord/trackCoordinate" },
            }),
          ]),
          _c("p", [_vm._v("说明：用于查询指定任务的轨迹坐标")]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v(
                    "**{api_url}/api/open/2022-12-21/taskRecord/trackCoordinate"
                  ),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("任务ID")]),
                _c("td", [_vm._v("taskExeId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"taskExeId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"task_execute_735a3060-4410-4ddc-8699-95175df1307c"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("限流参数")]), _vm._v("：无")]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("200")]),
                _c("td", [_vm._v("200:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("object")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("p", [_vm._v("data数据说明")]),
          _c("blockquote", [
            _c("p", [
              _vm._v(
                "坐标系是***wgs84***坐标，需根据地图平台需要自行做坐标转换"
              ),
            ]),
          ]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("坐标采集时间")]),
                _c("td", [_vm._v("time")]),
                _c("td", [_vm._v("datetime")]),
                _c("td", [_vm._v("2023-01-03 10:11:53")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("经度")]),
                _c("td", [_vm._v("lng")]),
                _c("td", [_vm._v("double")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("纬度")]),
                _c("td", [_vm._v("lat")]),
                _c("td", [_vm._v("double")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("前车角度(方向)")]),
                _c("td", [_vm._v("direction")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("170.0")]),
                _c("td", [_vm._v("[0,360）前车的角度是逆负顺正")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("速度")]),
                _c("td", [_vm._v("speed")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("0.7777")]),
                _c("td", [_vm._v("单位：m/s ,0.7777m/s")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("电量")]),
                _c("td", [_vm._v("soc")]),
                _c("td", [_vm._v("double")]),
                _c("td", [_vm._v("50.3")]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"获取轨迹坐标成功"'),
                ]),
                _vm._v(",\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": [{\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"time"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2023-01-03 10:27:23"'),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"lng"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("108.90730291026541"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"lat"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("34.240828937406675"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"direction"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-150.78067016601562"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"speed"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.1111111119389534"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"soc"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("88.0"),
                ]),
                _vm._v("\n\t}, {\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"time"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2023-01-03 10:27:24"'),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"lng"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("108.90729791109237"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"lat"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("34.24082093325509"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"direction"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-151.18003845214844"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"speed"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.6388888955116272"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"soc"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("88.0"),
                ]),
                _vm._v("\n\t}, {\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"time"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2023-01-03 10:27:26"'),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"lng"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("108.90729291172794"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"lat"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("34.24081392941596"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"direction"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("-150.89341735839844"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"speed"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0.7222222089767456"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"soc"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("88.0"),
                ]),
                _vm._v("\n\t}]\n}\n"),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("h2", [
        _vm._v("告警信息接口"),
        _c("a", { attrs: { name: "api-reference-warning" } }),
      ]),
      _c("ol", [
        _c("li", [
          _c("h3", [
            _vm._v("告警数据列表"),
            _c("a", { attrs: { name: "2022-12-21/vehicleWarn/list" } }),
          ]),
          _c("p", [_vm._v("说明：用于查询告警数据列表")]),
          _c("h4", [_c("strong", [_vm._v("接口路径")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("接口地址")])]),
                _c("th", [
                  _vm._v("**{api_url}/api/open/2022-12-21/vehicleWarn/list"),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("请求方式")])]),
                _c("td", [_c("strong", [_vm._v("POST")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("请求参数")])]),
          _c("p", [_c("strong", [_vm._v("header")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("授权")])]),
                _c("td", [_vm._v("Authorization")]),
                _c("td", [_c("strong", [_vm._v("string")])]),
                _c("td", [_c("strong", [_vm._v("是")])]),
                _c("td", [_c("strong", [_vm._v("max")])]),
              ]),
            ]),
          ]),
          _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("是否必要")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("否")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("客户端id")]),
                _c("td", [_vm._v("clientId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("时间戳")]),
                _c("td", [_vm._v("timestamp")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("版本号")]),
                _c("td", [_vm._v("version")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("签名")]),
                _c("td", [_vm._v("signature")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警严重程度")]),
                _c("td", [_vm._v("warnDegree")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("否")]),
                _c("td", [_vm._v("1普通、2严重")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警类型")]),
                _c("td", [_vm._v("warnType")]),
                _c("td", [_vm._v("Int")]),
                _c("td", [_vm._v("否")]),
                _c("td", [
                  _vm._v(
                    "1 实时告警 2 历史告警， 该参数不传则默认查实时告警，否则按实际查询"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("页码")]),
                _c("td", [_vm._v("pageNum")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("是")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("每页条数")]),
                _c("td", [_vm._v("pageSize")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("是")]),
                _c("td", [_vm._v("最多20条")]),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"v8888"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"warnDegree"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("20"),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clientId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"xxxxxxxxxxxxxxxxx"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"signature"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v(
                    '"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"'
                  ),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"timestamp"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-09-15T09:30:22Z"'),
                ]),
                _vm._v(",\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"version"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2021-11-22"'),
                ]),
                _vm._v("\n}\n"),
              ]
            ),
          ]),
          _c("h4", [_c("strong", [_vm._v("响应数据")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("信息")]),
                _c("td", [_vm._v("msg")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("代码")]),
                _c("td", [_vm._v("code")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("200")]),
                _c("td", [_vm._v("200:成功，其他:失败")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("数据")]),
                _c("td", [_vm._v("data")]),
                _c("td", [_vm._v("object")]),
                _c("td"),
                _c("td", [_vm._v("相关数据参见示例")]),
              ]),
            ]),
          ]),
          _c("p", [_vm._v("data数据说明")]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("总计条数")]),
                _c("td", [_vm._v("total")]),
                _c("td", [_vm._v("int")]),
                _c("td", [_vm._v("10")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("具体数据")]),
                _c("td", [_vm._v("rows")]),
                _c("td", [_vm._v("数组")]),
                _c("td"),
                _c("td", [_vm._v("具体数据")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("页码")]),
                _c("td", [_vm._v("pageNum")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("每页条数")]),
                _c("td", [_vm._v("pageSize")]),
                _c("td", [_vm._v("int")]),
                _c("td"),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_vm._v("rows数据说明")]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("字段名")])]),
                _c("th", [_c("strong", [_vm._v("变量名")])]),
                _c("th", [_c("strong", [_vm._v("类型")])]),
                _c("th", [_c("strong", [_vm._v("举例")])]),
                _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("车辆编号")]),
                _c("td", [_vm._v("vehicleId")]),
                _c("td", [_vm._v("string")]),
                _c("td", [_vm._v("V8888")]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警类型")]),
                _c("td", [_vm._v("warnType")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td", [_vm._v("1：实时告警 2：历史告警")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警级别")]),
                _c("td", [_vm._v("level")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td", [
                  _vm._v(
                    "-1：未知告警，100：只做展示使用， 200：会导致任务自动暂停(可自动恢复) ，300：会导致任务自动停止(不会自动恢复)"
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警描述")]),
                _c("td", [_vm._v("warnDesc")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警触发时间")]),
                _c("td", [_vm._v("triggedTime")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警类别")]),
                _c("td", [_vm._v("typeId")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td", [_vm._v("参见后面<<告警类别说明>>")]),
              ]),
              _c("tr", [
                _c("td", [_c("s", [_vm._v("告警状态")])]),
                _c("td", [_c("s", [_vm._v("warnStatus")])]),
                _c("td", [_c("s", [_vm._v("string")])]),
                _c("td"),
                _c("td", [_c("s", [_vm._v("1待处理 2已处理")])]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("告警实例id")]),
                _c("td", [_vm._v("uuid")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td", [
                  _vm._v("warning_100ba1be-13d7-4bc2-9ad5-01d3bbd7357d"),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("清除告警时间")]),
                _c("td", [_vm._v("clearTime")]),
                _c("td", [_vm._v("string")]),
                _c("td"),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", [_c("strong", [_vm._v("示例：")])]),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-json" } },
              [
                _vm._v("{\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"msg"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"操作成功"'),
                ]),
                _vm._v(",\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"code"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("200"),
                ]),
                _vm._v(",\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"data"'),
                ]),
                _vm._v(": {\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageNum"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"pageSize"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"total"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("55"),
                ]),
                _vm._v(",\n\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"rows"'),
                ]),
                _vm._v(": [{\n\t\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"v8888"'),
                ]),
                _vm._v(",\n\t\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"warnType"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"1"'),
                ]),
                _vm._v(",\n\t\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"level"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"200"'),
                ]),
                _vm._v(",\n\t\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"warnDesc"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"不在智驾状态,处于人工或遥控状态"'),
                ]),
                _vm._v(",\n\t\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"triggedTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2023-02-01 15:03:37"'),
                ]),
                _vm._v(",\n\t\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"typeId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"ID_Vcu_CtrlM"'),
                ]),
                _vm._v(",\n\t\t\t"),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"uuid"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"warning_100ba1be-13d7-4bc2-9ad5-01d3bbd7357d"'),
                ]),
                _vm._v("\n\t\t}, {\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"level"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"100"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"clearTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2023-02-23 19:01:19"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"warnType"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"warnDesc"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"刹车人工介入1"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"uuid"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"warning_0d65c279-dede-4418-9810-a201736f8846"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"triggedTime"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"2023-02-23 19:01:17"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"typeId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Node_Vcu_101"'),
                ]),
                _vm._v(",\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v('"vehicleId"'),
                ]),
                _vm._v(": "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"v8888"'),
                ]),
                _vm._v("\n   }]\n\t}\n}\n"),
              ]
            ),
          ]),
          _c("p", [_c("strong", [_vm._v("告警类别说明")])]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("告警ID(typeId 告警类别 )")]),
                _c("th", [_vm._v("告警描述")]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Vcu_Alive")]),
                _c("td", [_vm._v("VCUClient节点无心跳（节点启动失败或崩溃）")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_LidarD_Alive")]),
                _c("td", [_vm._v("雷达相关节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_LidarP_Alive")]),
                _c("td", [_vm._v("雷达相关节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Imu_Alive")]),
                _c("td", [_vm._v("IMU节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Http_Alive")]),
                _c("td", [_vm._v("httpgateway节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Mqtt_Alive")]),
                _c("td", [_vm._v("ccclient节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Bm_Alive")]),
                _c("td", [_vm._v("businessmanager节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Loc_Alive")]),
                _c("td", [_vm._v("定位相关节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Rout_Alive")]),
                _c("td", [_vm._v("Rout节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Plan_Alive")]),
                _c("td", [_vm._v("planning节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Pecp_Alive")]),
                _c("td", [_vm._v("perception节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Ctrl_Alive")]),
                _c("td", [_vm._v("control节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Param_Alive")]),
                _c("td", [_vm._v("paramserver节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_Pecp_lidar_det_Alive")]),
                _c("td", [_vm._v("lidar_detect节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Node_CameraD_Alive")]),
                _c("td", [_vm._v("camera_detect节点无心跳")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Vcu_Freq")]),
                _c("td", [_vm._v("VCU底盘数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Vcu_EStop")]),
                _c("td", [_vm._v("按下急停按钮（紧急刹车）")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Vcu_Soc")]),
                _c("td", [_vm._v("电池电量过低")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Msf_Freq")]),
                _c("td", [_vm._v("定位数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Msf_Loc")]),
                _c("td", [_vm._v("定位状态异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Imu_Freq")]),
                _c("td", [_vm._v("IMU数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Gps_Delay")]),
                _c("td", [_vm._v("GPS数据延迟")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Vcu_CtrlM")]),
                _c("td", [_vm._v("退出自动驾驶状态")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Control_Freq")]),
                _c("td", [_vm._v("控制数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Plan_Freq")]),
                _c("td", [_vm._v("规划数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Plan_DrCtr_TL")]),
                _c("td", [_vm._v("信号灯导致任务暂停")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Plan_DrCtr_BJ")]),
                _c("td", [_vm._v("交通拥堵导致任务暂停")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Lidar_SCAN1_Freq")]),
                _c("td", [_vm._v("雷达数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Lidar_SCAN2_Freq")]),
                _c("td", [_vm._v("雷达数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Lidar_SCAN3_Freq")]),
                _c("td", [_vm._v("雷达数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Lidar_Pointcloud1_Freq")]),
                _c("td", [_vm._v("点云数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Lidar_Pointcloud2_Freq")]),
                _c("td", [_vm._v("点云数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Lidar_Pointcloud3_Freq")]),
                _c("td", [_vm._v("点云数据频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Lidar_Det_Freq")]),
                _c("td", [_vm._v("雷达数据处理结果输出频率异常")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("ID_Chn_Pecp_Freq")]),
                _c("td", [_vm._v("融合算法数据频率异常")]),
              ]),
              _c("tr", [_c("td"), _c("td")]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_101")]),
                _c("td", [_vm._v("刹车人工介入")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_201")]),
                _c("td", [_vm._v("超时故障")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_301～Node_Vcu_303")]),
                _c("td", [_vm._v("原车故障等级1~3")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_401～Node_Vcu_403")]),
                _c("td", [_vm._v("电池故障等级1～3")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_501～Node_Vcu_503")]),
                _c("td", [_vm._v("EHB故障等级1～3")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_601")]),
                _c("td", [_vm._v("电机故障")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_701")]),
                _c("td", [_vm._v("制动液不足警告")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_801～Node_Vcu_803")]),
                _c("td", [_vm._v("驻车故障等级1～3")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Node_Vcu_901")]),
                _c("td", [_vm._v("电机控制器故障")]),
              ]),
              _c("tr", [_c("td"), _c("td")]),
              _c("tr", [
                _c("td", [_vm._v("Node_HWM_1~Node_HWM_2")]),
                _c("td", [
                  _vm._v("磁盘空间不足（天准机器上1代表主机，2代表从机）"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _vm._v("关于接口限制"),
      _c("a", { attrs: { name: "api-limit-description" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("h2", [
          _vm._v("IP白名单"),
          _c("a", { attrs: { name: "ip-white-list" } }),
        ]),
        _c("p", [
          _vm._v(
            "为了保证接口服务的安全性，目前对外开放接口服务有IP白名单限制，只有在白名单中的IP才能访问接口服务。"
          ),
          _c("br"),
          _c("strong", [_vm._v("IP白名单的设定方法")]),
          _vm._v(
            "：登录本平台系统，在左侧菜单依次点击选择“开发管理”->“我的开发者信息”，打开所选页面后，点击“IP白名单”旁边的“修改白名单”按钮，在随后出现的输入框内输入白名单IP地址，点击“保存”按钮即可。"
          ),
        ]),
      ]),
      _c("li", [
        _c("h2", [
          _vm._v("接口限流"),
          _c("a", { attrs: { name: "api-limit" } }),
        ]),
        _c("p", [
          _vm._v(
            "为了给客户提供稳定可靠的接口服务，防止因为某个接口的请求量过大导致系统崩溃，目前对外开放接口服务有对各接口进行限流，以限制每个接口的请求量。"
          ),
          _c("br"),
          _vm._v(" 限流规则如下："),
        ]),
        _c("ol", [
          _c("li", [_vm._v("每个接口的限流值默认为每分钟10次请求。")]),
          _c("li", [
            _vm._v(
              "限流的单位是每分钟，每个接口的限流值是每分钟的请求量，超过限流值的请求会被拒绝。"
            ),
          ]),
          _c("li", [
            _vm._v(
              "各接口有指定限流参数，接口根据限流参数不同的值来限定每分钟的请求量；如果接口没有指定限流参数，则该接口只根据接口本身来限定每分钟的请求量。"
            ),
            _c("br"),
            _vm._v(" 比如： "),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "某个接口的限流参数是vehicleId，那么该接口的限流以vehicleId参数值来计算，表示该接口相同的vehicleId值每分钟的请求量不能超过10次。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "某个接口没有设限流参数，那么该接口的限流以接口本身来计算，表示该接口不管传什么参数值，整体上都是每分钟的请求量不能超过10次。"
                ),
              ]),
            ]),
          ]),
          _c("li", [_vm._v("限流值的修改需要联系于万科技的工作人员。")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }